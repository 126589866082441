
/**
 * @name: expertLook
 * @author: Gzm
 * @date: 2023-06-03 10:09
 * @description：expertLook
 * @update: 2023-06-03 10:09
 */
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {ICoachByPage} from "@/apis/finance/coachReconcile/types";
import {
  queryTalentCommissionByPageApi
} from "@/apis/finance/coachReconcile";
import {deepCopy} from "@/utils/common";
import Qrcode from "qrcodejs2";

@Component({})
export default class ProductBasic extends Vue {
  @Prop(Boolean) dialogVisible!: boolean;
  @Prop(Object) data!: object;
  dialogCode: boolean = false
  queryForm: any = {
    page: 1,
    limit: 10
  }
  total: number = 0

  tableData: ICoachByPage[] = []

  ultimoData: any = {}

  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: any = {
    searchBox: true,
    menu: false,
    column: [
      {
        label: "订单编号",
        prop: "orderSn",
        align: 'center',
        width: 200,
      },
      {
        label: "订单类型",
        prop: "orderTypes",
        align: 'center',
        value: 2,
        type: "switch",
        search: true,
        dicData: [
          {label: '商城订单', value: 1},
          {label: '旅游订单', value: 2},
          {label: '赛事订单', value: 3},
          {label: '1v1课程订单', value: 4},
          {label: '网红订单', value: 5},
          {label: '视频课程订单', value: 6}],
      },
      {
        label: "价格",
        prop: "productPrice",
        align: 'center',
      },
      {
        label: "下单客户",
        prop: "userName",
        align: 'center',
      },

      {
        label: "佣金",
        prop: "userCommission",
        align: 'center',
      },
      {
        label: "支付方式",
        prop: "payWay",
        align: 'center',
        value: 2,
        type: "switch",
        dicData: [{label: '余额', value: 1}, {label: '微信支付', value: 2}],
        search: true,

      },

      {
        "label": "订单时间",
        "prop": "payTime",
        "align": "center",
        "width": 150,
        "type": "daterange",
        "search": true,
      },
      {
        label: "发放状态",
        prop: "commissionStatus",
        align: 'center',
        value: 2,
        type: "switch",
        dicData: [{label: '未发放', value: 1}, {label: '已发放', value: 2}],
      },

      {
        label: "订单编号",
        prop: "orderSn",
        align: 'center',
        search: true,
        hide: true
      },
    ]
  }
  dialogCodeData: any = {}

  /**
   * 获取数据
   */
  getList() {

    const query: any = deepCopy(this.queryForm)
    if (this.queryForm.payTime && this.queryForm.payTime.length === 2) {
      query.payTimeStart = this.queryForm.payTime[0]
      query.payTimeEnd = this.queryForm.payTime[1]
    } else {
      query.payTimeStart = ''
      query.payTimeEnd = ''
    }
    delete query.payTime
    queryTalentCommissionByPageApi(query).then(e => {
      this.tableData = e.list;
      this.total = e.total
    })
  }

  get computedData() {
    return this.data;
  }

  @Watch('computedData')
  onChangeValue(newVal: any, oldVal: any) {
    this.queryForm.id = newVal.id
    this.getList()
  }

  handleCancel(val: boolean) {
    this.$emit('Change', val)
  }

  handleOpen(row: any) {
    this.dialogCode = true
    this.dialogCodeData = row
    setTimeout(() => {
      this.$nextTick(() => {
        const dom = document.querySelector(`#qrcode`)
        dom!.innerHTML = '';
        new Qrcode(dom, {
          text: row.ticketCode,
          width: 200,
          height: 200
        })
      })
    }, 0)
  }
}
